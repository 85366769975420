body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: GT-Walsheim;
	font-weight: 200;
	src: url(/fonts/GT-Walsheim-Ultra-Light.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 300;
	src: url(/fonts/GT-Walsheim-Thin.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 300;
	src: url(/fonts/GT-Walsheim-Light.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 400;
	src: url(/fonts/GT-Walsheim-Regular.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 500;
	src: url(/fonts/GT-Walsheim-Medium.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 600;
	src: url(/fonts/GT-Walsheim-Bold.woff2) format("woff2");
}