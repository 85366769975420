.container a:link, .container a:visited, .container a:hover, .container a:active {
	color: inherit;
	font-weight: bold;
	text-emphasis: none;
    text-decoration: none;
}

.subtitle {
	font-weight: lighter;
}

div:global(.main) section div.buttonSection {
	column-gap: 50px;
	row-gap: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 30%);
	justify-content: center;
	align-items: center;
}
@media only screen and (max-width: 600px) {
	div:global(.main) section div.buttonSection {
		grid-template-columns: unset;;
		grid-template-rows: repeat(3, 1fr);
		width: unset;
	}
}

div:global(.main) section div.priceButton:global(.download) {
	display: grid;
	grid-auto-rows: 35px;
	row-gap: 30px;
	font-size: large;
	padding: 20px;
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.95);
	height: 100%;
	width: 100%;
	max-width: unset;
	margin: 0px;
}

div.priceButton > * {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: pre;
	word-break: break-word;
	overflow-wrap: break-word;
	width: 100%;
}

div.priceButton > a {
	background-color: gold;
	width: 100%;
}

.description {
	font-weight: 300;
	line-height: 1.5em;
	background-color: rgba(128, 250, 203, 0.8);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: left;
}

.notice {
	font-style: italic;
}

/* Pricing */

.calculator {
	width: 100%;
	margin: 10px 0;
	display: grid;
	grid-auto-flow: column;
	column-gap: 40px;
	font-weight: 300;
	line-height: 1.5em;
	background-color: rgba(250, 138, 203, 0.8);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: left;
}
@media only screen and (max-width: 600px) {
	.calculator {
		grid-auto-flow: row;
	}
}

.calculator label {
	display: grid;
	row-gap: 5px;
	box-sizing: border-box;
	font-weight: 400;
}
.calculator input {
	border-radius: 5px;
	padding: 5px;
	box-sizing: border-box;
	outline: none;
	border: none;
	background-color: rgb(255, 251, 227);
}
.calculator label:global(#answer) input {
	background-color: rgba(128, 250, 203, 0.8);
}