.container {
	background-color: rgba(180, 180, 245, 0.0);
}

@media only screen and (max-width: 700px) {
	.container {
		background-image: none;
	}
}

.container a:link, .container a:visited, .container a:hover, .container a:active {
	text-emphasis: none;
    text-decoration: none;
}

.container div:global(.wrapper) > * {
	margin: 20px 0 10px 0;
}

.container h1 {
	margin-top: 0px;
	margin-bottom: 0px;
}

@media only screen and (max-width: 700px) {
	.container h1 {
		font-size: 24px;
	}
}

.container .description {
	color: rgba(20, 20, 20. 0.8);
	display: block;
	font-size: 18px;
	line-height: 1.5em;
}

.subtitle {
	display: flex;
	width: 100%;
	justify-content: center;
	box-sizing: border-box;
	padding-top: 20px;
	font-weight: 300;
	font-style: italic;
}

/* Sections */

:global(#awards) {
	background-color: rgba(255, 217, 0, 0.356);
}