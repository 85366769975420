.header {
    position: fixed;
    top: 0px;
    height: 12vh;
    width: 100vw;
    z-index: 4; /* On account on react-carousel */
    background-color: rgba(255, 255, 255, 0);

    display: grid;
    grid-template-columns: 12vw 35vw 1fr 3vw;
    grid-column-gap: 3%;
    align-items: start;
    font-size: 20px;
    transition: all 0.33s ease;
}
.header.scrolled {
    height: 8vh;
    background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.5);
}

.header > div.logoWrapper {
    position: relative;
    height: 15vh;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
	width: 80%;
	
}

.logo {
	height: 100%;
	pointer-events: none;
}


.header > div {
    width: 100%;
    height: inherit;
}

.menuButton {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5vh;
}

.mobileMenu {
	background-color: rgba(190, 211, 255, 0.95);
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 3;
	overflow: hidden;
	transition: max-height 0.5s ease;
}

.mobileMenu a {
	color: black;
    text-emphasis: none;
	text-decoration: none;
	border-bottom: rgba(50, 50, 50, 0.4) solid 2px;
	padding: 20px;
	width: 80%;
}

.mobileMenu hr {
	background-color: white;
	width: 80%;
	height: 5px;
}

.buttonContainer {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-column-gap: 5%;
    align-items: center;
}

.button {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 400;
    padding: 10px 0;
    border: 1px solid rgba(128, 128, 128, 0);
}
.header.scrolled .button {
    border-radius: 5px;
}
.header:not(.scrolled) .button {
    border-bottom: 1px solid rgba(128, 128, 128, 0.39);
    text-underline-offset: 2px;
}
.header:not(.scrolled) .button:hover {
    border-bottom: 1px solid rgba(128, 128, 128, 0.9);
}
.header.scrolled .button:hover {
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.header .nameWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    font-size: larger;
    text-align: left;
}

.header div.name {
    height: auto;
    display: inline-block;
    text-align: left;
    margin: 12px 0;
}

@media only screen and (max-width: 700px) {
    div.header {
        grid-template-columns: 80fr 20fr;
        grid-column-gap: 0%;
    }
}

.header a:link, .header a:visited, .header a:hover, .header a:active {
    color: black;
    text-emphasis: none;
    text-decoration: none;
}