.text {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	white-space: nowrap;
	width: 28ch;
	padding-left: 9ch;
	font-size: 48px;
	font-weight: bolder;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
	margin: 40px 0 20px 0;
	font-family: 'Roboto', Whitney, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 700px) {
	.text {
		font-size: 24px;
	}
}

.text > div {
	position: relative;
	overflow: hidden;
}

.your {
	display: flex;
	position: relative;
}
.your > * {
	position: relative;
}

.expand {
	animation: expand 2s ease forwards;
}
.contract {
	animation: contract 1s ease forwards;
}
.slideLeft {
	animation: slide-left 2s ease forwards;
}
.slideRight {
	animation: slide-right 1s ease forwards;
}

@keyframes expand {
	from {
		max-width: 0px;
	}
	to {
		max-width: 14ch;
	}
}
@keyframes contract {
	from {
		max-width: 14ch;
	}
	to {
		max-width: 0px;
	}
}

@media only screen and (max-width: 700px) {
	@keyframes expand {
		from {
			max-width: 0px;
		}
		to {
			max-width: 18ch;
		}
	}
}

@keyframes slide-right {
	0% {
		right: 0%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: -100%;
	}
}
@keyframes slide-left {
	0% {
		right: -100%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: 0%;
	}
}

.fadeIn {
	animation: fade-in 4s ease-out forwards;
}

@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	50% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}