.wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 40px;
	row-gap: 40px;
	align-self: center;
	grid-template-areas:
		'title title'
		'jimmy peter'
		'jimmy dan'
		'christian james';
	padding: 40px;
	box-sizing: border-box;
	justify-content: center;
}

@media only screen and (max-width: 600px) {
	.wrapper {
		grid-template-columns: unset;
		grid-template-areas:
			'title'
			'jimmy'
			'peter'
			'dan'
			'christian'
			'james';
	}

}

.title, .title * {
	grid-area: title;
	text-align: left;
	margin-block-end: 0px;
}

.review {
	position: relative;
	background-color: rgba(255, 111, 111, 0.25);
	width: 100%;
	height: 100%;
	padding: 30px 40px 0px 50px;
	box-sizing: border-box;
	text-align: left;
	border-radius: 5px;
}

@media only screen and (max-width: 600px) {
	.review {
		max-width: 80vw;
	}
}

.leftQuote {
	position: absolute;
	left: 1ch;
	top: 20px;
}

.rightQuote {
	position: absolute;
	right: 1ch;
}

.author {
	font-weight: 500;
	white-space: normal;
}