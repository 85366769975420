:global(#story) {
	background-color: rgba(255, 243, 71, 0.4);
}

:global(#author) {
	background-color: rgba(211, 197, 120, 0.4);
}

:global(#development) {
	background-color: rgba(77, 190, 230, 0.4);
}

:global(#contact) {
	background-color: rgba(77, 255, 187, 0.68);
}

section:global(#story) :global(.quote) {
	position: relative;
}
@media only screen and (max-width: 600px) {
	section:global(#story) :global(.quote) {
		max-width: 85vw;
	}
}

.leftQuote {
	position: absolute;
	left: -2ch;
	top: -2ch;
}

.rightQuote {
	position: absolute;
	bottom: 0px;
	right: 1ch;
}

.title {
	font-size: x-large;
}

.subtitle {
	font-weight: lighter;
}

.signature {
	height: 100px;
}

/* Depth: 3 */

.columns {
	grid-template-columns: repeat(3, 1fr);
	row-gap: 20px;
	justify-content: center;
}
.columns > div {
	height: 100%;
	border-radius: 5px;
	padding: 10px 30px;
	box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
	.columns {
		grid-template-columns: unset;
		grid-template-rows: repeat(3, 1fr);
	}
	.columns > div {
		max-width: 85vw;
	}
}

.columns :global(#efficiency) {
	background-color: rgba(143, 191, 255, 0.6);
}
.columns :global(#ingenuity) {
	background-color: rgba(231, 255, 123, 0.6);
}
.columns :global(#inclusivity) {
	background-color: rgba(240, 180, 180, 0.6);
}