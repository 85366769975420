.container {
	display: flex;
	align-items: center;
	padding: 50px;
	flex-direction: column;
}

.container > * {
	max-width: 980px;
}

.container:global(#overview) > div {
	width: 100%;
	margin-top: 20px;
}

/* Depth: 3 */

.grid {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 40px;
	row-gap: 40px;
	align-self: center;
	grid-template-areas:
		'pairings results'
		'pairings players'
		'tie-Breaks players'
		'. players';
	padding: 40px;
}
.grid.results {
	grid-template-areas:
		'pairings standings'
		'results profiles'
		'enhancements enhancements';
}
@media only screen and (max-width: 600px) {
	.grid {
		grid-template-columns: unset;
		grid-template-areas:
			'pairings'
			'results'
			'players'
			'tie-Breaks';
		padding: 40px 5px;
	}
	.grid.results {
		grid-template-areas:
			'pairings'
			'standings'
			'results'
			'profiles'
			'enhancements';
	}
	div.box {
		padding: 30px 20px;
	}
}

.box {
	background-color: rgba(255, 163, 163, 0.5);
	width: 100%;
	height: 100%;
	padding: 30px 40px;
	box-sizing: border-box;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: auto 1fr;
	column-gap: 10px;
	row-gap: 5px;
	align-items: center;
	text-align: left;
	border-radius: 5px;
}
.box.r {
	background-color: rgba(249, 223, 130, 0.6);
}

.box .title {
	font-weight: bold;
	grid-column: span 2;
	margin-block-end: 0.5em;
}
.box svg {
	color: green;
}

/* ScorchApp */

:global(#about) {
	background-color: rgba(199, 214, 255, 0.8);
}
:global(#features) {
	background-color: rgba(92, 255, 179, 0.4);
}
:global(#uniques) {
	background-color: rgba(180, 150, 182, 0.3);
}
:global(#drag-and-drop) {
	background-color: rgba(240, 150, 182, 0.3);
}
:global(#tiebreak-drag) {
	background-color: rgba(180, 200, 182, 0.3);
}

/* ScorchResults */

section.view:not(:last-of-type) {
	margin-bottom: -15px;
	padding: 0px;
}
@media only screen and (max-width: 600px) {
	section.view:not(:last-of-type) {
		margin-bottom: unset;
		padding: 20px 0;
	}
}
.view :global(.column-text) {
	justify-content: center;
}

:global(#results-about) {
	background-color: rgba(160, 255, 160, 0.5);
}
:global(#results-features) {
	background-color: rgba(240, 190, 190, 0.6);
}
:global(#views) {
	background-color: rgba(123, 169, 255, 0.4);
}
:global(#knockout) {
	background-color: rgba(200, 169, 255, 0.4);
}
:global(#pairings-table) {
	background-color: rgba(255, 169, 244, 0.5);
}
:global(#standings-table) {
	background-color: rgba(123, 169, 255, 0.4);
}
:global(#finals) {
	background-color: rgba(224, 255, 88, 0.5);
}
:global(#stats) {
	background-color: rgba(45, 241, 255, 0.2);
}

/* */

.container:global(#overview) {
	background-color: rgba(199, 214, 255, 0.8);
}

.container:global(#tournament-director) {
	background-color: rgba(11, 11, 11, 0.9);
	text-shadow: 1px 1px 2px black;
}

.container:global(#results) {
	background-color: rgba(135, 206, 235, 0.95);
	color: black;
}

.container:global(#mobile) {
	background-color: rgba(0, 94, 55, 0.8);
	color: white;
}

.container:global(#workflow) {
	background-color: rgba(255, 159, 159, 0.85);
	color: black;
}

.carousel {
	margin: 20px 0;
}

.carousel div:global(.carousel) li:global(.slide) {
	background: transparent;
}

.verticalCarousel div:global(.carousel-slider) {
	max-width: 240px;
}

div:global(.main) .container > .subtitle {
	font-weight: 300;
}

.container:global(#results) .subtitle, .container:global(#mobile) .subtitle {
	font-weight: 300;
}

.description {
	font-weight: 300;
	line-height: 1.5em;
}

.description li {
	text-align: left;
}

div:global(.main) section.container .download {
	font-weight: bold;
}