
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	background-color: #282c341f;
	width: 100%;
}

.row1 {
	display: grid;
	grid-auto-flow: column;
	column-gap: 80px;
	align-items: center;
}
.icons {
    margin: 20px 0;
    display: grid;
	grid-auto-flow: column;
	justify-content: center;
}

@media only screen and (max-width: 700px) {
	.icons {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: unset;
	}
}

.icons > a {
    margin: 0 10px;
}

.icons > a > svg, .icons > a > svg > *, .icons > a > img {
    width: 50px;
    height: 50px;
    color: rgb(40, 40, 70);
    transition: opacity 0.25s;
    opacity: 1.0;
}
.icons > a > img {
	filter: brightness(2.3) hue-rotate(300deg) saturate(8);
}

.icons > a > svg:hover, .icons > a > img:hover {
    opacity: 0.5;
}

.author {
	display: flex;
	margin-bottom: 20px;
	font-size: 14px;
}