.grid {
	visibility: visible;
}

.carousel {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100%;
	align-self: center;
	margin: auto;
}
	
.carousel > div {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.carousel div img {
	width: 100%;
	display: block;
	max-width: 100vw;
	margin: 0;
}

@media only screen and (max-width: 700px) {
	.carousel div img {
		max-width: unset;
	}
	div:global(.carousel) div:global(.thumbs-wrapper) button {
		display: none;
	}

	div:global(.carousel) ul:global(.thumbs) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		transform: unset!important;
	}
}

.carousel div:hover {
	cursor: pointer;
}

/* Text Gallery */

div.textGallery {
    justify-items: center;
	overflow: hidden;
	width: 100vw;
	max-width: 100vw;
	margin: 0;
}

.selection {
    display: flex;
    flex-direction: row;
    justify-content: center;;
    margin: 10px 0;
}

.stage {
    position: relative;
    left: -100vw;
    transition: left 1s;
	display: grid;
	grid-auto-flow: column;
    grid-auto-columns: 100vw;
    justify-items: center;
}

.stage > * {
	max-width: 980px;
}

.stage > div {
	display: flex;
	align-items: center;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

.dotSelected {
    background-color: #002147;
}