@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: GT-Walsheim;
	font-weight: 200;
	src: url(/fonts/GT-Walsheim-Ultra-Light.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 300;
	src: url(/fonts/GT-Walsheim-Thin.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 300;
	src: url(/fonts/GT-Walsheim-Light.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 400;
	src: url(/fonts/GT-Walsheim-Regular.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 500;
	src: url(/fonts/GT-Walsheim-Medium.woff2) format("woff2");
}
@font-face {
	font-family: GT-Walsheim;
	font-weight: 600;
	src: url(/fonts/GT-Walsheim-Bold.woff2) format("woff2");
}
/* Depth: 0 */

#root {
	display: flex;
	font-family: Roboto,"Source Sans Pro","Open Sans","San Francisco",Helvetica,Arial,sans-serif;
	text-align: center;
    justify-content: center;
	font-size: larger;
	color: rgba(10, 10, 20, 1)
}

/* Depth: 1 */

.background-gradient {
	background-color: white;
	background-image: linear-gradient(
		160deg,
		rgba(4, 36, 116, 0.6),
		rgba(70, 83, 109, 0.8),
		rgba(164, 52, 62, 0.78),
		rgba(152, 81, 86, 0.85)
	);
	z-index: -2;
	-webkit-filter: brightness(1.8);
	        filter: brightness(1.8);
	position: fixed;
	height: 100%;
	width: 100%;
}

.background {
	background-image: url('/images/background.png');
	opacity: 0.5;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	background-color: white;
	position: fixed;
	height: 100%;
	width: 100%;
}

.main {
	display: flex;
	flex-direction: column;
	font-family: 'GT-Walsheim';
	padding-top: 12vh;
	z-index: 1;
	width: 100%;
}
.main * {
	scroll-margin-top: 8vh;
}

/* Depth: 2 */

.main > section, .main > div {
    flex: 0 1 auto;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 0px 30px 0px;
}

.main > section a:link, .main > section  a:visited, .main > section a:hover, .main > section a:active {
	color: inherit;
	font-weight: bold;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
	text-decoration: none;
	cursor: pointer;
}

@media only screen and (max-width: 700px) {
	.App {
		display: flex;
		flex-direction: column;
	}
	@supports not ((display: flex) or (display: grid)) {
		.main {
			display: flex;
		}
	}
}

/* Depth: 3 */

.wrapper {
	width: 1040px;
	max-width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.wrapper > :not(div):not(a):not(img) {
	align-self: flex-start;
	text-align: left;
}

/* Depth: 4 */

.columns {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	-webkit-column-gap: 30px;
	        column-gap: 30px;
	grid-row-gap: 30px;
	row-gap: 30px;
}
@media only screen and (max-width: 600px) {
	.columns {
		grid-template-columns: unset;
		grid-auto-rows: auto;
	}

}
.column-text {
	display: flex;
	flex-direction: column;
	text-align: left;
	box-sizing: border-box;
	align-items: stretch;
	justify-content: center;
}

/* Additional */

.column-text > *:first-child {
	-webkit-margin-before: 0px;
	        margin-block-start: 0px;
}
p:first-of-type {
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
}
ol, ul {
	-webkit-margin-before: 0.5em;
	        margin-block-start: 0.5em;
	-webkit-margin-after: 0px;
	        margin-block-end: 0px;
}

.normal {
	font-weight: normal;
}

.highlighted {
	color: rgb(233, 198, 0);
}
@supports (-webkit-text-stroke: 1px black) {
	.highlighted {
	  -webkit-text-stroke: 0.5px black;
	}
}

.bold {
	font-weight: bold;
}

.bolder {
	font-weight: bolder;
}

.underlined {
	text-decoration: underline;
}

.strikethrough {
	text-decoration: line-through;
}

.main label, .light {
	font-weight: 300;
}

/* Buttons */

.buttonSection {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	justify-content: center;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

@media only screen and (max-width: 700px) {
	.buttonSection {
		grid-auto-rows: 1fr;
		grid-auto-flow: row;
	}
}

.main section a.download, .main section div.download {
	margin: 10px 30px;
	border: 0;
	padding: 1em 75px;
	border-radius: 5px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	position: relative;
	top: -2px;
	white-space: nowrap;
	max-width: 10vw;
	display: flex;
	justify-content: center;
	font-weight: normal;
	text-shadow: none;
}

@media only screen and (max-width: 700px) {
	.download {
		margin: 10px 0;
	}
}

.main section a.download:hover, .main section div.download:hover {
	top: 0px;
}

.main section a.platform {
	color: black;
	background-color: white;
}

a.platform > svg {
	padding: 0 5px;
}

.main section a.web {
	color: white;
	background-color: #7289da;
}

/* General Table Styles */



table {
	width: 100%;
}

table tr {
	width: 100%;
}

table thead tr {
    background-color: rgba(134, 134, 255, 0.8);
	text-shadow: 1px 1px 2px black;
}

table > tbody tr:nth-child(odd) {
    background-color: rgba(53, 135, 175, 0.31);
}

table > tbody tr:nth-child(odd) td:hover, table > tbody tr:nth-child(odd) th:hover {
    background-color: rgba(53, 135, 175, 0.6);
}

table > tbody tr:nth-child(even) {
    background-color: rgba(252, 161, 161, 0.31);
}

table > tbody tr:nth-child(even) td:hover, table > tbody tr:nth-child(even) th:hover {
    background-color: rgba(252, 161, 161, 0.6);
}

table > tbody > tr > th {
	padding: 5px 1em
}
.titlebar_header__1UwVW {
    position: fixed;
    top: 0px;
    height: 12vh;
    width: 100vw;
    z-index: 4; /* On account on react-carousel */
    background-color: rgba(255, 255, 255, 0);

    display: grid;
    grid-template-columns: 12vw 35vw 1fr 3vw;
    grid-column-gap: 3%;
    align-items: start;
    font-size: 20px;
    transition: all 0.33s ease;
}
.titlebar_header__1UwVW.titlebar_scrolled__pYBne {
    height: 8vh;
    background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.5);
}

.titlebar_header__1UwVW > div.titlebar_logoWrapper__3n_7j {
    position: relative;
    height: 15vh;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
	width: 80%;
	
}

.titlebar_logo__RM5xp {
	height: 100%;
	pointer-events: none;
}


.titlebar_header__1UwVW > div {
    width: 100%;
    height: inherit;
}

.titlebar_menuButton__eo1z6 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5vh;
}

.titlebar_mobileMenu__mE25i {
	background-color: rgba(190, 211, 255, 0.95);
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 3;
	overflow: hidden;
	transition: max-height 0.5s ease;
}

.titlebar_mobileMenu__mE25i a {
	color: black;
    -webkit-text-emphasis: none;
            text-emphasis: none;
	text-decoration: none;
	border-bottom: rgba(50, 50, 50, 0.4) solid 2px;
	padding: 20px;
	width: 80%;
}

.titlebar_mobileMenu__mE25i hr {
	background-color: white;
	width: 80%;
	height: 5px;
}

.titlebar_buttonContainer__3X5kr {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-column-gap: 5%;
    align-items: center;
}

.titlebar_button__3Fko6 {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 400;
    padding: 10px 0;
    border: 1px solid rgba(128, 128, 128, 0);
}
.titlebar_header__1UwVW.titlebar_scrolled__pYBne .titlebar_button__3Fko6 {
    border-radius: 5px;
}
.titlebar_header__1UwVW:not(.titlebar_scrolled__pYBne) .titlebar_button__3Fko6 {
    border-bottom: 1px solid rgba(128, 128, 128, 0.39);
    text-underline-offset: 2px;
}
.titlebar_header__1UwVW:not(.titlebar_scrolled__pYBne) .titlebar_button__3Fko6:hover {
    border-bottom: 1px solid rgba(128, 128, 128, 0.9);
}
.titlebar_header__1UwVW.titlebar_scrolled__pYBne .titlebar_button__3Fko6:hover {
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.titlebar_header__1UwVW .titlebar_nameWrapper__2r9ym {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    font-size: larger;
    text-align: left;
}

.titlebar_header__1UwVW div.titlebar_name__2v4fc {
    height: auto;
    display: inline-block;
    text-align: left;
    margin: 12px 0;
}

@media only screen and (max-width: 700px) {
    div.titlebar_header__1UwVW {
        grid-template-columns: 80fr 20fr;
        grid-column-gap: 0%;
    }
}

.titlebar_header__1UwVW a:link, .titlebar_header__1UwVW a:visited, .titlebar_header__1UwVW a:hover, .titlebar_header__1UwVW a:active {
    color: black;
    -webkit-text-emphasis: none;
            text-emphasis: none;
    text-decoration: none;
}

.footer_footer__1WetU {
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	background-color: #282c341f;
	width: 100%;
}

.footer_row1__313TL {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 80px;
	-webkit-column-gap: 80px;
	        column-gap: 80px;
	align-items: center;
}
.footer_icons__3UNPQ {
    margin: 20px 0;
    display: grid;
	grid-auto-flow: column;
	justify-content: center;
}

@media only screen and (max-width: 700px) {
	.footer_icons__3UNPQ {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-flow: unset;
	}
}

.footer_icons__3UNPQ > a {
    margin: 0 10px;
}

.footer_icons__3UNPQ > a > svg, .footer_icons__3UNPQ > a > svg > *, .footer_icons__3UNPQ > a > img {
    width: 50px;
    height: 50px;
    color: rgb(40, 40, 70);
    transition: opacity 0.25s;
    opacity: 1.0;
}
.footer_icons__3UNPQ > a > img {
	-webkit-filter: brightness(2.3) hue-rotate(300deg) saturate(8);
	        filter: brightness(2.3) hue-rotate(300deg) saturate(8);
}

.footer_icons__3UNPQ > a > svg:hover, .footer_icons__3UNPQ > a > img:hover {
    opacity: 0.5;
}

.footer_author__WkeBA {
	display: flex;
	margin-bottom: 20px;
	font-size: 14px;
}
#story {
	background-color: rgba(255, 243, 71, 0.4);
}

#author {
	background-color: rgba(211, 197, 120, 0.4);
}

#development {
	background-color: rgba(77, 190, 230, 0.4);
}

#contact {
	background-color: rgba(77, 255, 187, 0.68);
}

section#story .quote {
	position: relative;
}
@media only screen and (max-width: 600px) {
	section#story .quote {
		max-width: 85vw;
	}
}

.about_leftQuote__28Ci9 {
	position: absolute;
	left: -2ch;
	top: -2ch;
}

.about_rightQuote__1CxyM {
	position: absolute;
	bottom: 0px;
	right: 1ch;
}

.about_title__2SlRz {
	font-size: x-large;
}

.about_subtitle__2rfcH {
	font-weight: lighter;
}

.about_signature__3pXe3 {
	height: 100px;
}

/* Depth: 3 */

.about_columns__24lHg {
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 20px;
	row-gap: 20px;
	justify-content: center;
}
.about_columns__24lHg > div {
	height: 100%;
	border-radius: 5px;
	padding: 10px 30px;
	box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
	.about_columns__24lHg {
		grid-template-columns: unset;
		grid-template-rows: repeat(3, 1fr);
	}
	.about_columns__24lHg > div {
		max-width: 85vw;
	}
}

.about_columns__24lHg #efficiency {
	background-color: rgba(143, 191, 255, 0.6);
}
.about_columns__24lHg #ingenuity {
	background-color: rgba(231, 255, 123, 0.6);
}
.about_columns__24lHg #inclusivity {
	background-color: rgba(240, 180, 180, 0.6);
}
.faqs_container__zm9k2 {
	display: flex;
	align-items: center;
	padding: 0px 50px 50px 50px;
	flex-direction: column;
}
.faqs_container__zm9k2:first-child {
	padding: 50px;
}

.faqs_container__zm9k2 a:link, .faqs_container__zm9k2 a:visited, .faqs_container__zm9k2 a:hover, .faqs_container__zm9k2 a:active {
	color: inherit;
	font-weight: bold;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
    text-decoration: none;
}

.faqs_container__zm9k2 a:link, .faqs_container__zm9k2 a:visited, .faqs_container__zm9k2 a:hover, .faqs_container__zm9k2 a:active {
	-webkit-text-emphasis: none;
	        text-emphasis: none;
	color: inherit;
	font-weight: bold;
}

.faqs_container__zm9k2#header {
	background-color: rgba(11, 11, 50, 0.5);
}

.faqs_container__zm9k2#scorchapp {
	background-color: rgba(11, 11, 11, 0.9);
}

.faqs_container__zm9k2 > div {
	margin: 20px 0;
	max-width: 980px;
	display: grid;
	grid-column-gap: 20px;
	-webkit-column-gap: 20px;
	        column-gap: 20px;
	grid-template-columns: 1fr auto auto 1fr;
	grid-template-rows: auto auto;
	grid-template-areas:
		'. question question version'
		'answer answer answer answer';
}

.faqs_title__21PUq {
	font-size: xx-large;
	font-weight: bolder;
	margin: 5px 0;
}
.faqs_question__39afJ {
	font-weight: bold;
	margin: 20px 0;
	grid-area: question;
}

.faqs_version__3wmx- {
	margin: 20px 0px;
	grid-area: version;
	font-weight: 200;
	font-size: 12px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.faqs_answer__62bmH {
	text-align: justify;
	grid-area: answer;
	font-weight: 300;
}
.components_grid__48VAo {
	visibility: visible;
}

.components_carousel__1DzUX {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100%;
	align-self: center;
	margin: auto;
}
	
.components_carousel__1DzUX > div {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.components_carousel__1DzUX div img {
	width: 100%;
	display: block;
	max-width: 100vw;
	margin: 0;
}

@media only screen and (max-width: 700px) {
	.components_carousel__1DzUX div img {
		max-width: unset;
	}
	div.carousel div.thumbs-wrapper button {
		display: none;
	}

	div.carousel ul.thumbs {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		transform: unset!important;
	}
}

.components_carousel__1DzUX div:hover {
	cursor: pointer;
}

/* Text Gallery */

div.components_textGallery__37irB {
    justify-items: center;
	overflow: hidden;
	width: 100vw;
	max-width: 100vw;
	margin: 0;
}

.components_selection__2C-76 {
    display: flex;
    flex-direction: row;
    justify-content: center;;
    margin: 10px 0;
}

.components_stage__YmPVf {
    position: relative;
    left: -100vw;
    transition: left 1s;
	display: grid;
	grid-auto-flow: column;
    grid-auto-columns: 100vw;
    justify-items: center;
}

.components_stage__YmPVf > * {
	max-width: 980px;
}

.components_stage__YmPVf > div {
	display: flex;
	align-items: center;
}

.components_dot__1EtCe {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

.components_dotSelected__11clv {
    background-color: #002147;
}
.features_container__2K6SD {
	display: flex;
	align-items: center;
	padding: 50px;
	flex-direction: column;
}

.features_container__2K6SD > * {
	width: 100%;
	max-width: 980px;
	margin: 20px 0;
}

.features_container__2K6SD a:link, .features_container__2K6SD a:visited, .features_container__2K6SD a:hover, .features_container__2K6SD a:active {
	color: inherit;
	font-weight: bold;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
    text-decoration: none;
}

.features_container__2K6SD#highlights {
	background-color: rgba(255, 140, 0, 0.8);
	text-shadow: 1px 1px 2px black;
}

.features_container__2K6SD#features {
	background-color: rgba(100, 0, 0, 0.8);
}

.features_title__rzK1d {
	font-size: xx-large;
	font-weight: bolder;
	margin: 5px 0;
}

.features_subtitle__3aeIH {
	font-weight: lighter;
}

tr.features_section__3OQpB th {
	padding: 5px;
}

tr.features_entry__g6Huv th {
	text-align: left;
	font-weight: normal;
	font-size: 16px;
	min-height: 34px;
	word-break: break-all;
}

@media only screen and (max-width: 700px) {
	table thead tr.features_license__1tCxy th div.features_spacer__1QfZk {
		min-height: 9ch;
	}
	table thead tr.features_license__1tCxy th {
		transform: rotate(-90deg);
		min-height: 20px;
	}
}

table thead tr {
    background-color: rgba(0, 0, 50, 0.8);
	text-shadow: 1px 1px 2px black;
}

.features_container__2K6SD table > tbody > tr > td.features_dev__Etm2s {
	font-size: 12px;
	padding: 10px 0;
}
.home_container__1NmmU {
	background-color: rgba(180, 180, 245, 0.0);
}

@media only screen and (max-width: 700px) {
	.home_container__1NmmU {
		background-image: none;
	}
}

.home_container__1NmmU a:link, .home_container__1NmmU a:visited, .home_container__1NmmU a:hover, .home_container__1NmmU a:active {
	-webkit-text-emphasis: none;
	        text-emphasis: none;
    text-decoration: none;
}

.home_container__1NmmU div.wrapper > * {
	margin: 20px 0 10px 0;
}

.home_container__1NmmU h1 {
	margin-top: 0px;
	margin-bottom: 0px;
}

@media only screen and (max-width: 700px) {
	.home_container__1NmmU h1 {
		font-size: 24px;
	}
}

.home_container__1NmmU .home_description__2vR7W {
	color: rgba(20, 20, 20. 0.8);
	display: block;
	font-size: 18px;
	line-height: 1.5em;
}

.home_subtitle__2HQFU {
	display: flex;
	width: 100%;
	justify-content: center;
	box-sizing: border-box;
	padding-top: 20px;
	font-weight: 300;
	font-style: italic;
}

/* Sections */

#awards {
	background-color: rgba(255, 217, 0, 0.356);
}
.name_text__3avpk {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	white-space: nowrap;
	width: 28ch;
	padding-left: 9ch;
	font-size: 48px;
	font-weight: bolder;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
	margin: 40px 0 20px 0;
	font-family: 'Roboto', Whitney, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 700px) {
	.name_text__3avpk {
		font-size: 24px;
	}
}

.name_text__3avpk > div {
	position: relative;
	overflow: hidden;
}

.name_your__1yIgS {
	display: flex;
	position: relative;
}
.name_your__1yIgS > * {
	position: relative;
}

.name_expand__3_B7w {
	-webkit-animation: name_expand__3_B7w 2s ease forwards;
	        animation: name_expand__3_B7w 2s ease forwards;
}
.name_contract__cCuPp {
	-webkit-animation: name_contract__cCuPp 1s ease forwards;
	        animation: name_contract__cCuPp 1s ease forwards;
}
.name_slideLeft__2DlrS {
	-webkit-animation: name_slide-left__3WNId 2s ease forwards;
	        animation: name_slide-left__3WNId 2s ease forwards;
}
.name_slideRight__sgw1v {
	-webkit-animation: name_slide-right__1Cg-k 1s ease forwards;
	        animation: name_slide-right__1Cg-k 1s ease forwards;
}

@-webkit-keyframes name_expand__3_B7w {
	from {
		max-width: 0px;
	}
	to {
		max-width: 14ch;
	}
}

@keyframes name_expand__3_B7w {
	from {
		max-width: 0px;
	}
	to {
		max-width: 14ch;
	}
}
@-webkit-keyframes name_contract__cCuPp {
	from {
		max-width: 14ch;
	}
	to {
		max-width: 0px;
	}
}
@keyframes name_contract__cCuPp {
	from {
		max-width: 14ch;
	}
	to {
		max-width: 0px;
	}
}

@media only screen and (max-width: 700px) {
	@-webkit-keyframes name_expand__3_B7w {
		from {
			max-width: 0px;
		}
		to {
			max-width: 18ch;
		}
	}
	@keyframes name_expand__3_B7w {
		from {
			max-width: 0px;
		}
		to {
			max-width: 18ch;
		}
	}
}

@-webkit-keyframes name_slide-right__1Cg-k {
	0% {
		right: 0%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: -100%;
	}
}

@keyframes name_slide-right__1Cg-k {
	0% {
		right: 0%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: -100%;
	}
}
@-webkit-keyframes name_slide-left__3WNId {
	0% {
		right: -100%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: 0%;
	}
}
@keyframes name_slide-left__3WNId {
	0% {
		right: -100%;
	}
	80% {
		right: 0%;
	}
	100% {
		right: 0%;
	}
}

.name_fadeIn__3CTca {
	-webkit-animation: name_fade-in__370St 4s ease-out forwards;
	        animation: name_fade-in__370St 4s ease-out forwards;
}

@-webkit-keyframes name_fade-in__370St {
	0% {
		display: none;
		opacity: 0;
	}
	50% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes name_fade-in__370St {
	0% {
		display: none;
		opacity: 0;
	}
	50% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
.reviews_wrapper__fMZrB {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 40px;
	-webkit-column-gap: 40px;
	        column-gap: 40px;
	grid-row-gap: 40px;
	row-gap: 40px;
	align-self: center;
	grid-template-areas:
		'title title'
		'jimmy peter'
		'jimmy dan'
		'christian james';
	padding: 40px;
	box-sizing: border-box;
	justify-content: center;
}

@media only screen and (max-width: 600px) {
	.reviews_wrapper__fMZrB {
		grid-template-columns: unset;
		grid-template-areas:
			'title'
			'jimmy'
			'peter'
			'dan'
			'christian'
			'james';
	}

}

.reviews_title__26zuI, .reviews_title__26zuI * {
	grid-area: title;
	text-align: left;
	-webkit-margin-after: 0px;
	        margin-block-end: 0px;
}

.reviews_review__2pemQ {
	position: relative;
	background-color: rgba(255, 111, 111, 0.25);
	width: 100%;
	height: 100%;
	padding: 30px 40px 0px 50px;
	box-sizing: border-box;
	text-align: left;
	border-radius: 5px;
}

@media only screen and (max-width: 600px) {
	.reviews_review__2pemQ {
		max-width: 80vw;
	}
}

.reviews_leftQuote__4n53O {
	position: absolute;
	left: 1ch;
	top: 20px;
}

.reviews_rightQuote__3OtQd {
	position: absolute;
	right: 1ch;
}

.reviews_author__2wXe8 {
	font-weight: 500;
	white-space: normal;
}
.pricing_container__axHuj a:link, .pricing_container__axHuj a:visited, .pricing_container__axHuj a:hover, .pricing_container__axHuj a:active {
	color: inherit;
	font-weight: bold;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
    text-decoration: none;
}

.pricing_subtitle__37Nlo {
	font-weight: lighter;
}

div.main section div.pricing_buttonSection__2JkVe {
	grid-column-gap: 50px;
	-webkit-column-gap: 50px;
	        column-gap: 50px;
	grid-row-gap: 20px;
	row-gap: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 30%);
	justify-content: center;
	align-items: center;
}
@media only screen and (max-width: 600px) {
	div.main section div.pricing_buttonSection__2JkVe {
		grid-template-columns: unset;;
		grid-template-rows: repeat(3, 1fr);
		width: unset;
	}
}

div.main section div.pricing_priceButton__3SqcN.download {
	display: grid;
	grid-auto-rows: 35px;
	grid-row-gap: 30px;
	row-gap: 30px;
	font-size: large;
	padding: 20px;
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.95);
	height: 100%;
	width: 100%;
	max-width: unset;
	margin: 0px;
}

div.pricing_priceButton__3SqcN > * {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: pre;
	word-break: break-word;
	overflow-wrap: break-word;
	width: 100%;
}

div.pricing_priceButton__3SqcN > a {
	background-color: gold;
	width: 100%;
}

.pricing_description__2OgG2 {
	font-weight: 300;
	line-height: 1.5em;
	background-color: rgba(128, 250, 203, 0.8);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: left;
}

.pricing_notice__sP2XJ {
	font-style: italic;
}

/* Pricing */

.pricing_calculator__2fFKd {
	width: 100%;
	margin: 10px 0;
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 40px;
	-webkit-column-gap: 40px;
	        column-gap: 40px;
	font-weight: 300;
	line-height: 1.5em;
	background-color: rgba(250, 138, 203, 0.8);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: left;
}
@media only screen and (max-width: 600px) {
	.pricing_calculator__2fFKd {
		grid-auto-flow: row;
	}
}

.pricing_calculator__2fFKd label {
	display: grid;
	grid-row-gap: 5px;
	row-gap: 5px;
	box-sizing: border-box;
	font-weight: 400;
}
.pricing_calculator__2fFKd input {
	border-radius: 5px;
	padding: 5px;
	box-sizing: border-box;
	outline: none;
	border: none;
	background-color: rgb(255, 251, 227);
}
.pricing_calculator__2fFKd label#answer input {
	background-color: rgba(128, 250, 203, 0.8);
}
.products_container__1RStt {
	display: flex;
	align-items: center;
	padding: 50px;
	flex-direction: column;
}

.products_container__1RStt > * {
	max-width: 980px;
}

.products_container__1RStt#overview > div {
	width: 100%;
	margin-top: 20px;
}

/* Depth: 3 */

.products_grid__2cp1q {
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 40px;
	-webkit-column-gap: 40px;
	        column-gap: 40px;
	grid-row-gap: 40px;
	row-gap: 40px;
	align-self: center;
	grid-template-areas:
		'pairings results'
		'pairings players'
		'tie-Breaks players'
		'. players';
	padding: 40px;
}
.products_grid__2cp1q.products_results__FIFHI {
	grid-template-areas:
		'pairings standings'
		'results profiles'
		'enhancements enhancements';
}
@media only screen and (max-width: 600px) {
	.products_grid__2cp1q {
		grid-template-columns: unset;
		grid-template-areas:
			'pairings'
			'results'
			'players'
			'tie-Breaks';
		padding: 40px 5px;
	}
	.products_grid__2cp1q.products_results__FIFHI {
		grid-template-areas:
			'pairings'
			'standings'
			'results'
			'profiles'
			'enhancements';
	}
	div.products_box__1yvsd {
		padding: 30px 20px;
	}
}

.products_box__1yvsd {
	background-color: rgba(255, 163, 163, 0.5);
	width: 100%;
	height: 100%;
	padding: 30px 40px;
	box-sizing: border-box;
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: auto 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	grid-row-gap: 5px;
	row-gap: 5px;
	align-items: center;
	text-align: left;
	border-radius: 5px;
}
.products_box__1yvsd.products_r__VOhdN {
	background-color: rgba(249, 223, 130, 0.6);
}

.products_box__1yvsd .products_title__xfxZX {
	font-weight: bold;
	grid-column: span 2;
	-webkit-margin-after: 0.5em;
	        margin-block-end: 0.5em;
}
.products_box__1yvsd svg {
	color: green;
}

/* ScorchApp */

#about {
	background-color: rgba(199, 214, 255, 0.8);
}
#features {
	background-color: rgba(92, 255, 179, 0.4);
}
#uniques {
	background-color: rgba(180, 150, 182, 0.3);
}
#drag-and-drop {
	background-color: rgba(240, 150, 182, 0.3);
}
#tiebreak-drag {
	background-color: rgba(180, 200, 182, 0.3);
}

/* ScorchResults */

section.products_view__3oKf1:not(:last-of-type) {
	margin-bottom: -15px;
	padding: 0px;
}
@media only screen and (max-width: 600px) {
	section.products_view__3oKf1:not(:last-of-type) {
		margin-bottom: unset;
		padding: 20px 0;
	}
}
.products_view__3oKf1 .column-text {
	justify-content: center;
}

#results-about {
	background-color: rgba(160, 255, 160, 0.5);
}
#results-features {
	background-color: rgba(240, 190, 190, 0.6);
}
#views {
	background-color: rgba(123, 169, 255, 0.4);
}
#knockout {
	background-color: rgba(200, 169, 255, 0.4);
}
#pairings-table {
	background-color: rgba(255, 169, 244, 0.5);
}
#standings-table {
	background-color: rgba(123, 169, 255, 0.4);
}
#finals {
	background-color: rgba(224, 255, 88, 0.5);
}
#stats {
	background-color: rgba(45, 241, 255, 0.2);
}

/* */

.products_container__1RStt#overview {
	background-color: rgba(199, 214, 255, 0.8);
}

.products_container__1RStt#tournament-director {
	background-color: rgba(11, 11, 11, 0.9);
	text-shadow: 1px 1px 2px black;
}

.products_container__1RStt#results {
	background-color: rgba(135, 206, 235, 0.95);
	color: black;
}

.products_container__1RStt#mobile {
	background-color: rgba(0, 94, 55, 0.8);
	color: white;
}

.products_container__1RStt#workflow {
	background-color: rgba(255, 159, 159, 0.85);
	color: black;
}

.products_carousel__1k2uH {
	margin: 20px 0;
}

.products_carousel__1k2uH div.carousel li.slide {
	background: transparent;
}

.products_verticalCarousel__1X9o8 div.carousel-slider {
	max-width: 240px;
}

div.main .products_container__1RStt > .products_subtitle__34gYP {
	font-weight: 300;
}

.products_container__1RStt#results .products_subtitle__34gYP, .products_container__1RStt#mobile .products_subtitle__34gYP {
	font-weight: 300;
}

.products_description__1x6TY {
	font-weight: 300;
	line-height: 1.5em;
}

.products_description__1x6TY li {
	text-align: left;
}

div.main section.products_container__1RStt .products_download__2yeda {
	font-weight: bold;
}
.carousel .thumbs-wrapper {
	margin: 20px 0;
}
