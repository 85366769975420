/* Depth: 0 */

#root {
	display: flex;
	font-family: Roboto,"Source Sans Pro","Open Sans","San Francisco",Helvetica,Arial,sans-serif;
	text-align: center;
    justify-content: center;
	font-size: larger;
	color: rgba(10, 10, 20, 1)
}

/* Depth: 1 */

.background-gradient {
	background-color: white;
	background-image: linear-gradient(
		160deg,
		rgba(4, 36, 116, 0.6),
		rgba(70, 83, 109, 0.8),
		rgba(164, 52, 62, 0.78),
		rgba(152, 81, 86, 0.85)
	);
	z-index: -2;
	filter: brightness(1.8);
	position: fixed;
	height: 100%;
	width: 100%;
}

.background {
	background-image: url('/images/background.png');
	opacity: 0.5;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	background-color: white;
	position: fixed;
	height: 100%;
	width: 100%;
}

.main {
	display: flex;
	flex-direction: column;
	font-family: 'GT-Walsheim';
	padding-top: 12vh;
	z-index: 1;
	width: 100%;
}
.main * {
	scroll-margin-top: 8vh;
}

/* Depth: 2 */

.main > section, .main > div {
    flex: 0 1 auto;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 0px 30px 0px;
}

.main > section a:link, .main > section  a:visited, .main > section a:hover, .main > section a:active {
	color: inherit;
	font-weight: bold;
	text-emphasis: none;
	text-decoration: none;
	cursor: pointer;
}

@media only screen and (max-width: 700px) {
	.App {
		display: flex;
		flex-direction: column;
	}
	@supports not ((display: flex) or (display: grid)) {
		.main {
			display: flex;
		}
	}
}

/* Depth: 3 */

.wrapper {
	width: 1040px;
	max-width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.wrapper > :not(div):not(a):not(img) {
	align-self: flex-start;
	text-align: left;
}

/* Depth: 4 */

.columns {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	column-gap: 30px;
	row-gap: 30px;
}
@media only screen and (max-width: 600px) {
	.columns {
		grid-template-columns: unset;
		grid-auto-rows: auto;
	}

}
.column-text {
	display: flex;
	flex-direction: column;
	text-align: left;
	box-sizing: border-box;
	align-items: stretch;
	justify-content: center;
}

/* Additional */

.column-text > *:first-child {
	margin-block-start: 0px;
}
p:first-of-type {
    margin-block-start: 0px;
}
ol, ul {
	margin-block-start: 0.5em;
	margin-block-end: 0px;
}

.normal {
	font-weight: normal;
}

.highlighted {
	color: rgb(233, 198, 0);
}
@supports (-webkit-text-stroke: 1px black) {
	.highlighted {
	  -webkit-text-stroke: 0.5px black;
	}
}

.bold {
	font-weight: bold;
}

.bolder {
	font-weight: bolder;
}

.underlined {
	text-decoration: underline;
}

.strikethrough {
	text-decoration: line-through;
}

.main label, .light {
	font-weight: 300;
}

/* Buttons */

.buttonSection {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	justify-content: center;
	width: max-content;
}

@media only screen and (max-width: 700px) {
	.buttonSection {
		grid-auto-rows: 1fr;
		grid-auto-flow: row;
	}
}

.main section a.download, .main section div.download {
	margin: 10px 30px;
	border: 0;
	padding: 1em 75px;
	border-radius: 5px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	position: relative;
	top: -2px;
	white-space: nowrap;
	max-width: 10vw;
	display: flex;
	justify-content: center;
	font-weight: normal;
	text-shadow: none;
}

@media only screen and (max-width: 700px) {
	.download {
		margin: 10px 0;
	}
}

.main section a.download:hover, .main section div.download:hover {
	top: 0px;
}

.main section a.platform {
	color: black;
	background-color: white;
}

a.platform > svg {
	padding: 0 5px;
}

.main section a.web {
	color: white;
	background-color: #7289da;
}

/* General Table Styles */



table {
	width: 100%;
}

table tr {
	width: 100%;
}

table thead tr {
    background-color: rgba(134, 134, 255, 0.8);
	text-shadow: 1px 1px 2px black;
}

table > tbody tr:nth-child(odd) {
    background-color: rgba(53, 135, 175, 0.31);
}

table > tbody tr:nth-child(odd) td:hover, table > tbody tr:nth-child(odd) th:hover {
    background-color: rgba(53, 135, 175, 0.6);
}

table > tbody tr:nth-child(even) {
    background-color: rgba(252, 161, 161, 0.31);
}

table > tbody tr:nth-child(even) td:hover, table > tbody tr:nth-child(even) th:hover {
    background-color: rgba(252, 161, 161, 0.6);
}

table > tbody > tr > th {
	padding: 5px 1em
}