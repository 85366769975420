.container {
	display: flex;
	align-items: center;
	padding: 50px;
	flex-direction: column;
}

.container > * {
	width: 100%;
	max-width: 980px;
	margin: 20px 0;
}

.container a:link, .container a:visited, .container a:hover, .container a:active {
	color: inherit;
	font-weight: bold;
	text-emphasis: none;
    text-decoration: none;
}

.container:global(#highlights) {
	background-color: rgba(255, 140, 0, 0.8);
	text-shadow: 1px 1px 2px black;
}

.container:global(#features) {
	background-color: rgba(100, 0, 0, 0.8);
}

.title {
	font-size: xx-large;
	font-weight: bolder;
	margin: 5px 0;
}

.subtitle {
	font-weight: lighter;
}

tr.section th {
	padding: 5px;
}

tr.entry th {
	text-align: left;
	font-weight: normal;
	font-size: 16px;
	min-height: 34px;
	word-break: break-all;
}

@media only screen and (max-width: 700px) {
	table thead tr.license th div.spacer {
		min-height: 9ch;
	}
	table thead tr.license th {
		transform: rotate(-90deg);
		min-height: 20px;
	}
}

table thead tr {
    background-color: rgba(0, 0, 50, 0.8);
	text-shadow: 1px 1px 2px black;
}

.container table > tbody > tr > td.dev {
	font-size: 12px;
	padding: 10px 0;
}